<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<script lang="ts" setup>

import { reactive, computed, ref, watch } from 'vue'
import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'
import RoomieInput, { STATUS as INPUT_STATUS } from '@lahaus-roomie/roomie/src/components/RoomieInput/index.vue'
import RoomiePhoneInput from '@lahaus-roomie/roomie/src/components/RoomiePhoneInput/index.vue'
import UnlockIcon from '@lahaus-roomie/roomie/src/assets/icons/unlock.svg'

import type { Props, PhoneProperties } from './types'
import { getTriggerDate, getEntryNameAndEntryNameAlias } from '@/components/App/RegisterForm/utils'

import { getDomainUserId } from '@/utils/getDomainUserId'
import { validateEmail } from '@/utils/validations/emailValidation'
import { LISTING_SOURCE, TYPE_ATTENTION, SCREEN_CTA } from '@/utils/segment'
import type { LeadCreationRequestedTraits } from '@/utils/segment/types'
import { useContactFormStore } from '@/stores/contactFormStore'
import LegalPolicies from '@/components/LegalPolicies/index.vue'
import { useUserStore } from '@/stores/userStore'

const { t, locale } = useI18n()
const { $config } = useNuxtApp()

const userStore = useUserStore()

const emit = defineEmits(['submit'])

const legalPoliciesAccepted = ref(false)
const showWarningLegalPolicy = ref(false)

const opportunityTypeEventMap: Record<string, string> = {
  living: 'LIVING',
  investing: 'INVESTMENT'
}

const props = withDefaults(defineProps<Props>(), {
  listingIds: () => [],
  listingId: '',
  screen: '',
  totalSavings: 0,
  showEmailInput: true,
  showNameInput: true,
  showCtaIcon: false,
  attentionType: TYPE_ATTENTION.PRODUCT_BOT,
  showGeneralTitle: false
})

const contactFormStore = useContactFormStore()
const form = reactive({
  name: contactFormStore.userName,
  email: contactFormStore.userEmail,
  phone: contactFormStore.userPhone,
  phone_number: '',
  phone_prefix: '',
  phone_is_valid: false
})

const title = computed(() => props.showGeneralTitle ? t('titleGeneral') : t('title'))

const onboardingOpportunityType = useCookie<string>('onboarding_opportunity_type')
const onboardingBedroomsNumber = useCookie<number>('onboarding_bedrooms_number')

watch(() => contactFormStore.userPhone, () => {
  form.phone = contactFormStore.userPhone
  form.name = contactFormStore.userName
  form.email = contactFormStore.userEmail
})

const emailValidated = ref(false)

const emailStatus = computed(() => {
  if (!emailValidated.value) return INPUT_STATUS.default

  return validateEmail(form.email) ? INPUT_STATUS.success : INPUT_STATUS.error
})

const emailHelperText = computed(() => {
  if (emailStatus.value === INPUT_STATUS.success) return ''

  if (emailStatus.value === INPUT_STATUS.error) return form.email ? t('invalidEmail') : t('requiredEmail')

  return ''
})

const handleFormSubmit = async () => {
  emailValidated.value = true

  if (!legalPoliciesAccepted.value) {
    showWarningLegalPolicy.value = true
    return
  }

  showWarningLegalPolicy.value = false

  if (props.showEmailInput && emailStatus.value === INPUT_STATUS.error) return

  const isPhoneValid = form.phone_is_valid

  if (isPhoneValid) {
    emit('submit', form)

    contactFormStore.updateUserName(form.name)
    contactFormStore.updateUserEmail(form.email)
    contactFormStore.updateUserPhone(form.phone, false)
    contactFormStore.updateLegalConsent(legalPoliciesAccepted.value)
    await trackLeadCreationRequest()
    const isUserFound = await userStore.getUserByPhone(form.phone)

    if (isUserFound && $config.public.flagEnqueueTwilioFlowOnRegister === 'true') {
      props.listingIds.length > 0 || props.listingId ? enqueueTwilioContact() : sendSlackAlert('Usario se registró en SRP/Onboarding pero no tiene un match de listings en su busqueda')
    }

    if (!isUserFound) {
      sendSlackAlert('No se encontró el usuario en CDP despues del formulario de registro')
    }
  }
}

const enqueueTwilioContact = async () => {
  const triggerDate = getTriggerDate($config.public.environment)
  const flowPhone = locale.value === 'mx' ? $config.public.twilioOrchestratorFlowPhoneNumberMx : $config.public.twilioOrchestratorFlowPhoneNumberCo
  const user = userStore.user
  const listingIds = props.listingIds.length ? props.listingIds : [props.listingId]

  const { entryName, entryNameAlias } = getEntryNameAndEntryNameAlias(props.screen)

  if (user) {
    let name = user.name
    if (name.includes('Usuario sin')) name = ''

    const parameters = {
      entryName,
      listingIds,
      userId: user.id,
      leadName: name,
      leadEmail: user.email,
      leadCountryCode: locale.value.toUpperCase(),
      domainUserId: getDomainUserId(),
      entryNameAlias
    }
    try {
      await $fetch('/api/buyer-front/twilio/enqueue-flow', {
        method: 'POST',
        body: {
          flowId: $config.public.twilioOrchestratorFlowId,
          flowPhone,
          userPhone: user!.phone,
          triggerDate,
          parameters
        }
      })
    } catch (error) {
      useLogger().error({
        message: 'Error enqueuing for twilio flow'
      })
    }
  }
}

const sendSlackAlert = (title: string) => {
  $fetch('/api/buyer-front/send-slack-alert', {
    method: 'POST',
    body: {
      title,
      content: {
        userName: form.name,
        userEmail: form.email,
        userPhone: form.phone,
        url: window.location.href
      }
    }
  })
}
const { $trackLead } = useNuxtApp()

const trackLeadCreationRequest = async () => {
  const leadPayload = getLCRProperties()
  await $trackLead({
    phone: leadPayload.phone,
    listings: [
      leadPayload.listing_id
    ],
    screen: leadPayload.screen,
    screenCta: leadPayload.screen_cta,
    properties: leadPayload
  })

  // @ts-ignore
  window.dataLayer?.push({
    event: 'user-registration-requested',
    phone: leadPayload.phone,
    screen: leadPayload.screen,
    screen_cta: leadPayload.screen_cta
  })
}

const getLCRProperties = () => {
  const data = {
    phone: form.phone,
    phone_number: form.phone_number,
    phone_prefix: form.phone_prefix,
    screen: props.screen,
    screen_cta: SCREEN_CTA.INVESTMENT_REGISTRATION_FORM,
    location_of_interest_codes: [locale.value.toUpperCase()],
    budget_currency: t('currency'),
    type_attention: props.attentionType,
    purchase_purpose: onboardingOpportunityType.value && opportunityTypeEventMap[onboardingOpportunityType.value] ? opportunityTypeEventMap[onboardingOpportunityType.value] : 'INVESTMENT_AND_LIVING'
  } as LeadCreationRequestedTraits

  if (form.name) {
    data.name = form.name
  }

  if (form.email) {
    data.email = form.email.toLowerCase()
  }

  if (props.listingId) {
    data.listing_id = props.listingId
    data.listing_source = LISTING_SOURCE.PROPERTY_CATALOG
  } else if (props.listingIds.length) {
    data.listing_id = props.listingIds[0]
    data.listing_source = LISTING_SOURCE.PROPERTY_CATALOG
  }

  if (props.hubCode) {
    data.business_hub_code = props.hubCode
  }

  if (props.totalSavings) {
    data.down_payment_amount = props.totalSavings
  }

  if (props.monthlyPayments !== null) {
    data.monthly_payment_budget_max = props.monthlyPayments
  }

  if (onboardingBedroomsNumber.value) {
    data.bedrooms = onboardingBedroomsNumber.value
  }

  return data
}

const getPhoneProperties = (value:PhoneProperties) => {
  form.phone_number = value.phoneNumber
  form.phone_prefix = value.code
}

const roomieButtonDisabled = computed(() => {
  if (showWarningLegalPolicy.value) return true

  return !legalPoliciesAccepted.value
})

</script>

<script lang="ts">
export default {
  name: 'RegisterForm'
}
</script>

<template>
  <div class="register-form">
    <h2
      v-sanitize.basic="title"
      class="text-18 font-medium my-8 text-carbon-800" />

    <form
      class="mt-24 flex flex-col gap-y-16"
      @submit.prevent="handleFormSubmit">
      <RoomieInput
        v-if="showNameInput"
        id="contact-form-name"
        v-model="form.name"
        :required="true"
        label="Nombre completo"
        placeholder="Escríbe tu nombre completo"
        :is-internal-validate="false" />

      <RoomieInput
        v-if="showEmailInput"
        id="contact-form-email"
        v-model="form.email"
        :required="true"
        label="Correo"
        :status="emailStatus"
        :is-internal-validate="false"
        :helper-text="emailHelperText"
        @blur="emailValidated = true" />

      <RoomiePhoneInput
        id="lead-form-phone"
        name="lead-form-phone"
        :initial-country="locale"
        number-label="Número*"
        country-label="País"
        :is-required="true"
        :value="form.phone"
        @input="(newValue: string) => form.phone = newValue"
        @is-valid="(isValid: boolean) => form.phone_is_valid = isValid"
        @full="(value: PhoneProperties) => getPhoneProperties(value)" />

      <LegalPolicies
        v-model="legalPoliciesAccepted"
        :phone="form.phone"
        :show-warning="showWarningLegalPolicy"
        :screen="screen"
        :screen-cta="SCREEN_CTA.INVESTMENT_REGISTRATION_FORM" />

      <RoomieButton
        :id="`${id}-submit-button`"
        class="w-full gap-8 font-medium"
        :data-lh-id="`${id}-submit-button`"
        type="submit"
        :icon-placement="iconPlacement"
        :aria-label="t('cta')"
        :disabled="roomieButtonDisabled">
        <template #icon>
          <span>
            <NuxtIsland :name="iconName" />
          </span>
        </template>

        <span class="flex">
          <UnlockIcon class="h-24 w-24 fill-white mr-4" />
          {{ t('cta') }}
        </span>
      </RoomieButton>
    </form>
  </div>
</template>

<style lang="scss">
.register-form {
  .roomie-button.roomie-button--filled {
    &:disabled {
      @apply bg-carbon-light-50 text-carbon-light-600 opacity-50;

      border: 1px solid #919E9B;

      svg {
        @apply fill-carbon-light-600;
      }
    }
  }
}
</style>

<i18n src="./i18n.json" lang="json"></i18n>
