<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<script lang="ts" setup>

import { ref } from 'vue'
import type { Props } from './types'
import AppSyncRegisterForm from '@/components/App/SyncRegisterForm/index.vue'
import AppLoginForm from '@/components/App/LoginForm/index.vue'

const emit = defineEmits(['submit', 'sign-in'])

const props = withDefaults(defineProps<Props>(), {
  listingIds: () => [],
  listingId: '',
  screen: ''
})

const visibleForm = ref('sign-up-form')

const handleSignInEvent = () => {
  visibleForm.value = 'sign-in-form'
}

const handleSignUpEvent = () => {
  visibleForm.value = 'sign-up-form'
}

const handleSubmitEvent = (form) => {
  emit('submit', form)
}

</script>

<script lang="ts">
export default {
  name: 'UserIdentityContainer'
}
</script>

<i18n src="./i18n.json" lang="json"></i18n>

<template>
  <div>
    <AppSyncRegisterForm
      v-if="visibleForm === 'sign-up-form'"
      :id="`${screen}-registration-modal-form`"
      :listing-id="listingId"
      :screen="screen"
      @sign-in="handleSignInEvent"
      @submit="handleSubmitEvent" />

    <AppLoginForm
      v-else-if="visibleForm === 'sign-in-form'"
      :id="`${screen}-authentication-modal-form`"
      :listing-id="listingId"
      :screen="screen"
      @sign-up="handleSignUpEvent"
      @submit="handleSubmitEvent" />
  </div>
</template>
