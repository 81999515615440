<script lang="ts" setup>

import { reactive, ref } from 'vue'
import type { $Fetch } from 'ofetch'
import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'
import RoomieOtpInput from '@lahaus-roomie/roomie/src/components/RoomieOtpInput/index.vue'
import RoomiePhoneInput from '@lahaus-roomie/roomie/src/components/RoomiePhoneInput/index.vue'
import { STATUS as INPUT_STATUS } from '@lahaus-roomie/roomie/src/components/RoomieInput/index.vue'
import RoomieLoader from '@lahaus-roomie/roomie/src/components/RoomieLoader/index.vue'
import ChatIcon from '@lahaus-roomie/roomie/src/assets/icons/chat.svg'

import type { Props, PhoneProperties } from './types'

import { SCREEN_CTA } from '@/utils/segment'
import { useContactFormStore } from '@/stores/contactFormStore'
import LegalPolicies from '@/components/LegalPolicies/index.vue'
import { useUserStore } from '@/stores/userStore'

import AuthService from '@/services/authService'

const { t, locale } = useI18n()
const { $config } = useNuxtApp()

const userStore = useUserStore()

const emit = defineEmits(['submit', 'sign-up'])

const legalPoliciesAccepted = ref(false)
const showWarningLegalPolicy = ref(false)

const formStage = ref('PHONE_FORM')

const otpValue = ref('')

withDefaults(defineProps<Props>(), {
  listingIds: () => [],
  listingId: '',
  screen: ''
})

const contactFormStore = useContactFormStore()
const form = reactive({
  phone: contactFormStore.userPhone,
  phone_number: '',
  phone_prefix: '',
  phone_is_valid: false
})

const title = t('title')

const authService = new AuthService({
  apiFetch: globalThis.$fetch as $Fetch,
  authUrl: $config.public.authorizationApiUrl as string,
  authId: $config.public.laHausAuthId as string,
  xApplicationId: $config.public.environment
})

const phoneFormErrorMessage = ref('')

const handleFormSubmit = async () => {
  phoneFormErrorMessage.value = ''

  const isPhoneValid = form.phone_is_valid

  if (!isPhoneValid) return

  if (!legalPoliciesAccepted.value) {
    showWarningLegalPolicy.value = true
    return
  }

  showWarningLegalPolicy.value = false

  formStage.value = 'LOADING_FORM'

  try {
    await authService.authorizerToken()

    const captchaToken = await $getCaptchaToken()

    const result = await authService.authorizer(form.phone_prefix, form.phone_number, captchaToken)

    if (result?.challenge && result?.challenge === 'confirm_otp') {
      formStage.value = 'OTP_FORM'
    } else {
      formStage.value = 'PHONE_FORM'
      phoneFormErrorMessage.value = t('errors.phone_unexpected_error')
      sendSlackAlert('No el resultado del authorizer no fue confirm_otp', result)
    }
  } catch (error: any) {
    formStage.value = 'PHONE_FORM'
    phoneFormErrorMessage.value = t('errors.phone_unexpected_error')
  }
}

const sendSlackAlert = (title: string, extraPayload = {}) => {
  $fetch('/api/buyer-front/send-slack-alert', {
    method: 'POST',
    body: {
      title,
      content: {
        userPhone: form.phone,
        url: window.location.href,
        ...extraPayload
      }
    }
  })
}

const { $getCaptchaToken } = useNuxtApp()

const getPhoneProperties = (value:PhoneProperties) => {
  form.phone_number = value.phoneNumber
  form.phone_prefix = value.code
}

const otpButtonEnabled = ref(false)
const otpInputStaus = ref(INPUT_STATUS.default)
const otpInputStausMsg = ref('')

const filledOtp = () => {
  otpButtonEnabled.value = true
  otpInputStaus.value = INPUT_STATUS.default
}

const handleOtpSubmit = async () => {
  const captchaToken = await $getCaptchaToken()

  try {
    const result = await authService.challenge(otpValue.value, captchaToken)

    if (result?.auth_result) {
      userStore.setSession(result)
      emit('submit', form)
    } else {
      otpInputStausMsg.value = t('errors.unexpected_error')
      otpInputStaus.value = INPUT_STATUS.error
    }
  } catch (error: any) {
    otpInputStaus.value = INPUT_STATUS.error
    if (['invalid_code'].includes(error?.code)) {
      otpInputStausMsg.value = t(`errors.${error?.code}`)
    } else {
      otpInputStausMsg.value = t('errors.unexpected_error')
    }
  }
}

const resendOtp = async () => {
  const captchaToken = await $getCaptchaToken()

  try {
    const result = await authService.resendCode(captchaToken)

    if (result?.challenge === 'confirm_otp') {
      otpInputStausMsg.value = t('otpResendConfirmation')
      otpInputStaus.value = INPUT_STATUS.default
      otpValue.value = ''
    } else {
      otpInputStausMsg.value = t('errors.unexpected_error')
      otpInputStaus.value = INPUT_STATUS.error
    }
  } catch (error: any) {
    otpInputStaus.value = INPUT_STATUS.error
    otpInputStausMsg.value = t('errors.unexpected_error')
  }
}

const handleSignUpEvent = () => {
  emit('sign-up', form)
}

</script>

<script lang="ts">
export default {
  name: 'LoginForm'
}
</script>

<template>
  <div class="login-form">
    <template v-if="formStage === 'PHONE_FORM'">
      <h2
        v-sanitize.basic="title"
        class="text-18 font-medium mt-8 text-carbon-800" />

      <p class="text-14 text-carbon-300">
        {{ t('description') }}
      </p>

      <form
        class="mt-24 flex flex-col"
        @submit.prevent="handleFormSubmit">
        <RoomiePhoneInput
          id="lead-form-phone"
          name="lead-form-phone"
          class="mb-16"
          :initial-country="locale"
          number-label="Número*"
          country-label="País"
          :is-required="true"
          :value="form.phone"
          @input="(newValue: string) => form.phone = newValue"
          @is-valid="(isValid: boolean) => form.phone_is_valid = isValid"
          @full="(value: PhoneProperties) => getPhoneProperties(value)" />

        <LegalPolicies
          v-if="!contactFormStore.legalConsent"
          v-model="legalPoliciesAccepted"
          :phone="form.phone"
          :show-warning="showWarningLegalPolicy"
          :screen="screen"
          :screen-cta="SCREEN_CTA.INVESTMENT_REGISTRATION_FORM" />

        <p
          v-if="phoneFormErrorMessage.length"
          class="text-12 text-error-600 mb-20">
          {{ phoneFormErrorMessage }}
        </p>

        <RoomieButton
          :id="`${id}-submit-button`"
          class="w-full gap-8 font-medium"
          :data-lh-id="`${id}-submit-button`"
          type="submit"
          :aria-label="t('cta')">
          <span class="flex">
            <ChatIcon class="h-24 w-24 fill-white mr-8" />
            {{ t('cta') }}
          </span>
        </RoomieButton>
      </form>

      <div class="flex flex-col mt-32 gap-8">
        <div class="divider-text">
          <p>
            {{ t('register') }}
          </p>
        </div>

        <RoomieButton
          :id="`${id}-register-button`"
          class="w-full gap-8 font-medium"
          :data-lh-id="`${id}-register-button`"
          variant="outlined"
          :aria-label="t('registerCta')"
          @click="handleSignUpEvent">
          <span class="flex">
            {{ t('registerCta') }}
          </span>
        </RoomieButton>
      </div>
    </template>

    <div
      v-else-if="formStage === 'OTP_FORM'"
      class="flex flex-col gap-42">
      <div>
        <h2
          v-sanitize.basic="t('otpTitle')"
          class="text-18 font-medium mt-8 text-carbon-800" />

        <p
          v-sanitize.basic="t('otpDescription', { lastDigits: form.phone.slice(-2) })"
          class="text-14 text-carbon-300" />
      </div>

      <RoomieOtpInput
        id="roomie-input-otp"
        v-model="otpValue"
        :status="otpInputStaus"
        :helper-text="otpInputStausMsg"
        :time-limit="600"
        @time-up="otpButtonEnabled = false"
        @filled="filledOtp" />

      <p class="text-14 text-carbon-300">
        {{ t('otpResend') }}

        <a
          class="text-primary-600 underline cursor-pointer"
          @click="resendOtp">
          {{ t('otpResendCta') }}
        </a>
      </p>

      <div>
        <RoomieButton
          :id="`${id}-otp-button`"
          class="w-full font-medium"
          :data-lh-id="`${id}-otp-button`"
          :aria-label="t('otpCta')"
          :disabled="!otpButtonEnabled"
          @click="handleOtpSubmit">
          <span class="flex">
            {{ t('otpCta') }}
          </span>
        </RoomieButton>

        <a
          id="login-form-go-back-cta"
          class="block text-primary-600 underline cursor-pointer text-center mt-16"
          data-lh-id="login-form-go-back-cta"
          @click="formStage = 'PHONE_FORM'">
          {{ t('otpGoBackCta') }}
        </a>
      </div>
    </div>

    <div
      v-if="formStage === 'LOADING_FORM'"
      class="h-[337px] flex items-center justify-center">
      <RoomieLoader
        size="md"
        color="primary" />
    </div>
  </div>
</template>

<style lang="scss">
.login-form {
  .roomie-button.roomie-button--filled {
    &:disabled {
      @apply bg-carbon-light-50 text-carbon-light-600 opacity-50;

      border: 1px solid #919E9B;

      svg {
        @apply fill-carbon-light-600;
      }
    }
  }

  .roomie-otp-input__helper-text {
    margin-top: 0;
  }
}

.divider-text {
  @apply flex items-center w-full gap-16;

  p {
    @apply whitespace-nowrap;
  }

  &::before,
  &::after {
    @apply inline-block bg-carbon-light-300;

    content: '';
    width: 100%;
    height: 1px;
  }
}
</style>

<i18n src="./i18n.json" lang="json"></i18n>
